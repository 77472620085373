<template>
  <div class="trial-survey-intro">
    <div v-if="isSurveySRM" class="trial-survey-intro--language">
      <select-language/>
    </div>
    <div class="page">
      <div class="scroll">
        <div class="wrapper contents">
          <div class="title">{{survey.title}}</div>
          <div class="description" v-html="$sanitize(survey.description, sanitizeOptions)"/>
        </div>
        <div class="wrapper button-group">
          <button class="start-button" @click="start">{{ $t('start_survey') }}</button>
        </div>
      </div>
    </div>
    <copyright :survey-id="survey.id"/>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import { isMobile } from '@/common/browser.util';
import Copyright from '@/components/Common/Copyright.vue';
import bus from '@/common/bus.util';
import { SURVEY_ID_SRM } from '@/common/config';
import SelectLanguage from '@/components/Common/SelectLanguage.vue';

export default {
  name: 'TrialSurveyIntro',
  components: {
    SelectLanguage,
    Copyright,
  },
  computed: {
    ...mapGetters(['trialSurvey']),
    isMobile,
    surveyUrl() {
      return `/survey/${this.survey.id}/trial`;
    },
    isSurveySRM() {
      return this.survey.id === SURVEY_ID_SRM;
    },
  },
  data() {
    return {
      survey: {},
      sanitizeOptions: {
        allowedTags: ['a', 'p', 'br', 'img', 'strong', 'em', 'h2', 'u', 'li', 'ul'],
        allowedAttributes: {
          a: ['href', 'style', 'target'],
          img: ['src', 'style'],
        },
      },
    };
  },
  async created() {
    bus.$emit('start:spinner');
    this.setEntryPoint('FP');
    this.survey = await this.participateForFp(this.$route.params.id);
    if (!this.isMobile) {
        this.survey.description = this.survey.description.replace(/<img src=/gi, '<img style="max-width:960px;" src=');
    }
    bus.$emit('end:spinner');
  },
  methods: {
    ...mapActions(['getTrialSurvey', 'participateForFp']),
    ...mapMutations(['setAnswersClean', 'setEntryPoint', 'setParticipantToken']),
    start() {
      this.setAnswersClean();
      this.$router.push(`/fp/${this.$route.params.id}/trial`);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/style/variable.scss';
.trial-survey-intro {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 20px 0 10px;

  &--language {
    position: absolute;
    top: 20px;
    right: 20px;
  }
  .page {
    position: relative;
    width: 1140px;
    max-height: 918px;
    height: 100%;
    border-radius: 5px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    .scroll {
      display: flex;
      flex-direction: column;
      align-items: center;
      overflow: auto;
      height: calc(100% - 104px);
      width: 100%;
      .wrapper {
        max-width: 540px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        &.button-group {
          position: absolute;
          bottom: 48px;
          left: 50%;
          margin-left: -270px;
          .start-button {
            width: 100%;
            min-height: 48px;
            font-size: $font_size_16;
            font-weight: 600;
            margin-top: 60px;
          }
        }
        &.contents {
          padding-top: 60px;
          .title {
            font-size: $font_size_27;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.37;
            letter-spacing: normal;
            text-align: center;
            color: #2a324b;
            word-break: break-all;
            word-wrap: break-word;
            width: 100%;
            margin-bottom: 48px;
          }
          .description {
            display: flex;
            flex-direction: column;
            &::v-deep {
              color: red;
              @import "~@/assets/style/components/sally-email-template.scss";
            }
            img {
              max-width: 960px;
            }
          }
        }
      }
    }
  }
  .copyright {
    margin-top: 20px;
    margin-bottom: 0;
  }
}

@media only screen and (max-width: $max_width_size_for_mobile) {
  .trial-survey-intro {
    height: 100%;
    .page {
      height: 100%;
      width: 100%;
      position: relative;
      .scroll {
        overflow: auto;
        padding: 0 24px;
        height: calc(100% - 48px);
        .wrapper {
          position: initial;
          margin-bottom: 0;
          &.button-group {
            left: 0;
            margin-left: 0;
            .start-button {
              position: absolute;
              width: 100vw;
              bottom: -48px;
              left: 0;
            }
          }
          &.contents {
            margin-bottom: 3em;
          }
        }
      }
    }

    .copyright {
      display: none;
    }
  }
}

@media only screen and (min-width: $max_width_size_for_mobile)
  and (max-width: $max_width_size_for_tablet)
  and (orientation: landscape) {
  .trial-survey-intro {
    height: 100%;
    .page {
      width: 1340px;
      .scroll {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        height: calc(100% - 124px);
        overflow: auto;
        padding: 0 24px;
        .wrapper {
          display: flex;
          flex-direction: column;
          align-items: center;
          &.contents {
            padding-top: 63px;
          }
          &.button-group {
            position: absolute;
            width: 320px;
            margin-bottom: 0;
            bottom: 63px;
            left: calc(50% - 160px);
            margin-left: 0;
            .start-button {
              width: 100%;
              height: 48px;
            }
          }

        }
      }
    }
    .copyright {
      display: none;
    }
  }
}
</style>
